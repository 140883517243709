import React, { useEffect } from 'react'
import { useState } from 'react'
import { Button } from '@material-ui/core'
import { useEulaService } from './useEulaService'
import { Logo } from './Logo'
import NotAuthorized from './NotAuthorized'
import { makeStyles } from '@material-ui/core/styles'
import BottomScrollListener from 'react-bottom-scroll-listener'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '5rem',
    marginLeft: '5rem',
    backgroundColor: 'white',
    maxWidth: '100%',
    padding: '1rem',
  },
  text: {
    border: '1',
    borderColor: 'grey',
    borderStyle: 'solid',
    padding: '1.5rem',
    overflow: 'auto',
    height: '50vh',
  },
  form: {
    padding: '1rem',
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%',
  },
  margin: {
    margin: '2rem',
  },
  item: {
    margin: '0.5rem',
  },
  logo: {
    display: 'flex',
    marginBottom: '1rem',
    height: '3rem',
    justifyContent: 'center',
    width: '100%',
  },
  scrollHeader: {
    fontSize: '16pt',
    fontWeight: 800,
    color: 'red',
  },
})

export interface AccountMenuProperties {
  host?: string
  tokenFactory?: () => Promise<string>
  children: React.ReactNode
}

export function Eula({ host, tokenFactory, children }: AccountMenuProperties) {
  const classes = useStyles()
  const [disabled, setDisabled] = useState<boolean>(true)
  const [accept, setAccept] = useState<boolean>(false)
  const [declined, setDeclined] = useState<boolean>(false)
  const [completed, setCompleted] = useState<boolean>(false)
  const [display, setDisplay] = useState<boolean>(false)
  const [eulaContent, setEulaContent] = useState<string>()
  const [eulaId, setEulaId] = useState<string>()
  const { getEulasNotSigned, getEulaContent, signEula, eulas, error } =
    useEulaService({
      host,
      tokenFactory,
    })

  useEffect(() => {
    getEulasNotSigned()
  }, [host, tokenFactory])

  useEffect(() => {
    const getContent = async () => {
      if (eulas) {
        if (eulas.length > 0) {
          setDisplay(true)
          //get first eula
          for (var i = 0; i < 1; i++) {
            var content = await getEulaContent(eulas[i])
            setEulaId(eulas[i])
            setEulaContent(content)
          }
        } else {
          setCompleted(true)
        }
      }
    }
    getContent()
  }, [eulas])

  const contentBuilder = () => {
    if (error) {
      return {
        __html: `<h2>${error}</h2>`,
      }
    }
    return {
      __html: eulaContent || '',
    }
  }

  const handleAcceptEula = (event: React.ChangeEvent<HTMLInputElement>) => {
    var val = event.target.value
    var accepted = val === 'accept'
    setAccept(accepted)
  }

  const handleSignEula = async (
    event: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
  ) => {
    event.preventDefault()
    if (accept) {
      setEulaContent(undefined) // new to clear
      const elmnt = document.getElementById('divRef')
      setAccept(false)
      setDisabled(true)
      await signEula(eulaId || '0')
      if (elmnt) {
        elmnt.scrollTop = 0
      }
    } else {
      //they declined
      setDeclined(true)
    }
  }

  const handleContainerOnBottom = () => {
    setDisabled(false)
  }

  const renderEulaForm = () => {
    // no content we return null
    if (!eulaContent) {
      return null
    }
    return (
      <div className={classes.container}>
        <Logo className={classes.logo} />
        <div className={classes.scrollHeader}>
          You must read and scroll to the end of the EULA for 'submit' to become
          active.
        </div>
        <BottomScrollListener
          onBottom={handleContainerOnBottom}
          triggerOnNoScroll={true}
        >
          {(scrollRef) => (
            <div
              id={'divRef'}
              ref={scrollRef as React.RefObject<HTMLDivElement>}
              className={classes.text}
              dangerouslySetInnerHTML={contentBuilder()}
            />
          )}
        </BottomScrollListener>

        <form className={classes.form}>
          <div className={classes.row}>
            <div className={classes.margin}>
              <input
                onChange={handleAcceptEula}
                className={classes.item}
                type="radio"
                id="accept"
                name="accept"
                checked={accept}
                value="accept"
              />
              <label htmlFor="accept">ACCEPT</label>
            </div>
            <div className={classes.margin}>
              <input
                onChange={handleAcceptEula}
                className={classes.item}
                type="radio"
                id="decline"
                name="accept"
                checked={!accept}
                value="decline"
              />
              <label htmlFor="decline">DECLINE</label>
            </div>
          </div>
          <div className={classes.row}>
            <Button
              variant="contained"
              disabled={disabled}
              onClick={handleSignEula}
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    )
  }

  return completed ? (
    <>{children}</>
  ) : declined ? (
    <NotAuthorized />
  ) : display || error ? (
    renderEulaForm()
  ) : null
}
