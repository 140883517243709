import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    backgroundColor: '#F5F6F6',
    alignItems: 'center',
    justifyContent: 'center',
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    padding: '4rem 8rem',
    backgroundColor: '#FFF',
    borderRadius: 12,
    border: `3px solid #D2D5D7`,
    '&> h1': {
      fontSize: '14rem',
      color: '#EAECEE',
      lineHeight: 1,
    },
    '&> h3': {
      fontSize: '1.4rem',
      color: '#888B8D',
    },
  },
})

const MessageBox = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.info}>
        <h3>
          We cannot display your data at this time. Please sign the{' '}
          <a href="/">EULA</a> agreement in order to access the application.
        </h3>
      </div>
    </div>
  )
}

export const NotAuthorized = () => <MessageBox />

export default NotAuthorized
